import * as React from 'react';
import { useCookieConsentInjection } from '../hooks/cookie-consent-injection';

interface SebraeCookieConsentProps {
  uuidApp?: string;
  uuidUser?: string;
  debug?: boolean;
}

export const SebraeCookieConsent: React.FC<SebraeCookieConsentProps> = ({
  uuidApp = '',
  uuidUser = '',
  debug = false,
}) => {
  const uuidDomain = React.useMemo(() => uuidApp ?? '', [uuidApp]);
  const uuidCustomer = React.useMemo(() => uuidUser ?? '', [uuidUser]);

  const { injected } = useCookieConsentInjection(uuidDomain, uuidCustomer);

  React.useEffect(() => {
    if (debug) {
      if (uuidApp) {
        if (!injected) {
          return console.log(
            '[Sebrae Cookie Consent :: %s] Não injetou as depenências ainda para registrar a aplicação',
            uuidApp,
          );
        }

        console.log(
          '[Sebrae Cookie Consent :: %s] Opa! Já injetou as depenências',
          uuidApp,
        );

        if (!uuidUser) {
          return console.log(
            '[Sebrae Cookie Consent :: %s] Sem usuário para registrar',
            uuidApp,
          );
        }

        return console.log(
          '[Sebrae Cookie Consent :: %s] Registrando o usuário: %s',
          uuidApp,
          uuidUser,
        );
      }
      console.log('[Sebrae Cookie Consent] Sem UUID da aplicação');
    }
  }, [debug, uuidApp, uuidUser, injected]);

  return <></>;
};
